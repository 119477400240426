<template>


<v-layout class="pa-0">

  <v-flex justify-center>


<div v-resize="onResize">

  <v-data-table
    :headers="headers"
    :items="ArrayClientes"
    :search="search"
    :height="tableHeight"
    sort-by="calories"
    class="elevation-1 mx-2 mt-3 mb-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="opcion1">
        <v-toolbar-title>Historial clientes</v-toolbar-title>
        <v-divider  class="mx-4" inset vertical></v-divider>


        <v-flex  xs4 sm4 md3 lg3>
          <v-text-field  justify-center  v-model="search"  append-icon="search" 
          label="Busqueda" single-line hide-details></v-text-field> 
        </v-flex>


          <v-switch class="ml-5 mt-5"
              v-model="switch1"
              flat
              :label="LabelSwitch"
            ></v-switch>


            
        <v-icon  color="gray" class="mx-5" @click="verInfo()">info</v-icon>

           <v-flex  mx-md-3 mx-lg-4  mx-sm-2 mx-xs-1 mx-1>
                <v-btn dark color="primary" @click="dialogInicio=true">
                  <v-icon>date_range</v-icon>
                </v-btn>
            </v-flex>


                 <v-dialog v-model="dialogInicio" persistent max-width="250">
                  <v-card>

                    <v-toolbar flat color="red" class="text-center">
                    <v-toolbar-title class="white--text  text-center"><strong>Historico por fechas</strong></v-toolbar-title>
                  </v-toolbar>

                    
                    
                    <v-card-text>

                          <v-col class="d-inline p-3" cols="2">

                            <v-menu
                                    ref="menuFecha1"
                                    v-model="menuFecha1"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                
                                    offset-y
                                    min-width="290px"
                                  >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                            v-model="fecha_inicio_reporte"
                                            label="Fecha inicio"
                                            prepend-icon="event"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="fecha_inicio_reporte" locale="es"
                                        no-title  @input="menuFecha1 = false" scrollable>
                                        </v-date-picker>
                                  </v-menu>
                  </v-col>

                  <v-col class="d-inline" cols="2">
                                  <v-menu
                                    ref="menuFecha2"
                                    v-model="menuFecha2"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                
                                    offset-y
                                    min-width="290px"
                                  >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                            v-model="fecha_fin_reporte"
                                            label="Fecha fin"
                                            prepend-icon="event"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="fecha_fin_reporte" locale="es"
                                        no-title  @input="menuFecha2 = false" scrollable>
                                        </v-date-picker>
                                  </v-menu>

                                </v-col>


                      <v-flex justify-center d-flex>
                        <v-btn color="success" dark @click="listarClientes()">Generar</v-btn>
                          <v-btn @click="dialogInicio=false" style="margin-left:10px;">Cancelar</v-btn>
                      </v-flex>


                    </v-card-text>
                  

                      

                  </v-card>
                </v-dialog>


        <v-spacer></v-spacer>
        
      

      </v-toolbar>
    </template>


   
    <template v-slot:no-data>
      <v-btn color="primary" @click="listar()">
        Reset
      </v-btn>
    </template>


 

  </v-data-table>



</div>

  </v-flex>
</v-layout>
</template>

<script>

import axios from 'axios';
import { mapMutations,mapState } from "vuex";



  export default {
    components:{
    },
    data: () => ({
      search:'',

     headers: [
        { text: 'Nombre Cliente', value: 'nombre_cliente' },
        { text: 'Responsable', value: 'responsable' },  
        { text: 'Telefono', value: 'telefono' }, 
        { text: 'Ejecutivo', value:'id_ejecutivo_cli.nombre_ejecutivo'},
        { text: 'Correo', value:'correo'}
      ],
     
   
  
      ArrayClientes:[],
      ArrayHistorico:[],
      arreglo:[],

       tableHeight: 0,

       switch1:true,
       LabelSwitch:'Contactados',
       dialogInicio:false,
        menuFecha1:false,
        menuFecha2:false,
       fecha_inicio_reporte:new Date().toISOString().substr(0, 10),
       fecha_fin_reporte:new Date().toISOString().substr(0, 10),
       date: new Date().toISOString().substr(0, 10),
      


    


      
    }),

    computed: {
    
        ...mapState(['usuario', 'token']),

    },

    watch: {
   
      switch1(val){
          if(val==true){
             this.LabelSwitch='Contactados';
             this.ActulizarTabla();
          }else{
               this.LabelSwitch='Sin Contactar';
                this.ActulizarTabla();
          }
      }


    },

    created () {
      this.listarClientes();
    },

    methods: {

      ...mapMutations(['mostrarLoading','ocultarLoading']),
    
      listarClientes(){

          let config={headers:{token:this.token}};
          this.mostrarLoading({titulo:'Accediendo a datos'});
          let me=this;

          me.arreglo=[];
          axios.get('clientes-all',config
          ).then(function(response){
            console.log(response);
            me.ArrayClientes=[];
            me.arreglo=response.data;

              me.arreglo.map(function(x){
                 x.bandera_contactado=false;
              });

            me.listarGestiones();

           me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });


      },


      listarGestiones(){

              this.ArrayHistorico = [];

              let config={headers:{token:this.token}};
              this.mostrarLoading({titulo:'Accediendo a datos'});

              let parametros={ 'desde':this.fecha_inicio_reporte, 'hasta':this.fecha_fin_reporte };
              let me=this;
                
              axios.post('historicos',parametros,
                config
                ).then(function(response){
                  console.log(response);
                  me.ArrayHistorico=response.data;
                  me.dialogInicio=false;

                      me.arreglo.map(function(x){

                          //aqui solo ponemos los datos si es admin o si son del usuario ejecutivo actual
                          
                            if((me.usuario.rol=='ADMIN')||(x.id_ejecutivo_cli._id==me.usuario._id)){



                                if((me.switch1==true)&&(x.activo==1)){

                                      let indexGestionCliente = me.ArrayHistorico.findIndex(key => key.id_veh_cliente._id === x._id);

                                      console.log('respuesta busqeuda indice:'+indexGestionCliente);

                                      if(indexGestionCliente != -1){
                                             me.ArrayClientes.push(x);
                                      }
                                    
                                }
                                
                            }
                 
                    });

                  


                me.ocultarLoading();
                }).catch(function(error){
                  console.log(error);
                });

          },





  
      ActulizarTabla(){

         console.log('estado de swithc:'+this.switch1);

         let me=this;
          me.ArrayClientes=[];


             me.arreglo.map(function(x){

              //aqui solo ponemos los datos si es admin o si son del usuario ejecutvo actual
             
               if((me.usuario.rol=='ADMIN')||(x.id_ejecutivo_cli._id==me.usuario._id)){

                 if((me.switch1==true)&&(x.activo==1)){
                                   let indexGestionCliente = me.ArrayHistorico.findIndex(key => key.id_veh_cliente._id === x._id);

                                      console.log('respuesta busqeuda indice:'+indexGestionCliente);

                                      if(indexGestionCliente != -1){
                                             me.ArrayClientes.push(x);
                                      }
                 }

                  if((me.switch1==false)&&(x.activo==1)){
                        let indexGestionCliente = me.ArrayHistorico.findIndex(key => key.id_veh_cliente._id === x._id);

                                      console.log('respuesta busqeuda indice:'+indexGestionCliente);

                                      if(indexGestionCliente === -1){
                                             me.ArrayClientes.push(x);
                                      }
                 }

            



                   
               }

                  
                 
            });


       },

       verInfo(){
         alert("Observaciones:\n correos deben separarse por punto y comas, \n servidores pueden establecerse por pais: ni, sv, pa, gt o bien empresa ejemplo: avldiana");
       },

  
      getDate(evaluar) {
      let date = new Date(evaluar),
      year = date.getFullYear(),
      month = (date.getMonth() + 1).toString(),
      formatedMonth = (month.length === 1) ? ("0" + month) : month,
      day = date.getDate().toString(),
      formatedDay = (day.length === 1) ? ("0" + day) : day,
      hour = date.getHours().toString(),
      formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
      minute = date.getMinutes().toString(),
      formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
      second = date.getSeconds().toString(),
      formatedSecond = (second.length === 1) ? ("0" + second) : second;
        return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
      },

      getDateVence(evaluar) {
      let date = new Date(evaluar),
      year = date.getFullYear(),
      month = (date.getMonth() + 1).toString(),
      formatedMonth = (month.length === 1) ? ("0" + month) : month,
      day = date.getDate().toString(),
      formatedDay = (day.length === 1) ? ("0" + day) : day;
       return formatedDay + "-" + formatedMonth + "-" + year;
      },



     CalcularFechaHoy(){
	
        var fecha=new Date();
        var mes=fecha.getMonth()+1;
        if(mes<10)mes='0'+mes;
        var dia=fecha.getDate();
        if(dia<10)dia='0'+dia;
        var textofecha=fecha.getFullYear()+'-'+mes+'-'+dia;

        return(textofecha);
      },



      ConvertirFecha(fecha_parametro){

        let texto=new Date(fecha_parametro).toLocaleDateString()+" "+new Date(fecha_parametro).toLocaleTimeString();

        return(texto);

      },

       onResize() {

                if (window.innerWidth < 769){

                  //mobile
                  this.tableHeight = window.innerHeight - 20;
                }else{
                  //pantalla grande
                  this.tableHeight = window.innerHeight - 245;
                }
       },

 
  


    


      




    },
  }
</script>