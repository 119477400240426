<template>
  <v-card class="pt-0">
    <v-toolbar flat class="mt-n9">
      <template v-slot:extension>
        <v-tabs v-model="tabs" fixed-tabs>

          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#mobile-tabs-5-1" class="primary--text">
            <v-icon>connect_without_contact</v-icon>
          </v-tab>

          <v-tab href="#mobile-tabs-5-2" class="primary--text">
            <v-icon>account_circle</v-icon>
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tabs">

      <v-tab-item :value="'mobile-tabs-5-1'">
        <v-card flat>
          <v-card-text>
             <HistoricoGestiones />
          </v-card-text>
        </v-card>
      </v-tab-item>


      <v-tab-item :value="'mobile-tabs-5-2'">
        <v-card flat>
          <v-card-text>
             <HistoricoNoLlamada />
          </v-card-text>
        </v-card>
      </v-tab-item>


  

      
    </v-tabs-items>

  </v-card>
</template>

<script>


import HistoricoGestiones from './HistoricoGestiones.vue';
import HistoricoNoLlamada from './HistoricoNoLlamada.vue';


  export default {
      components:{
          HistoricoGestiones, HistoricoNoLlamada
         },
    data () {
      return {
        tabs: null,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      }
    },
  }
</script>