<template>


<v-layout class="pa-0">

  <v-flex justify-center>

<div v-resize="onResize">
  <v-data-table
    :headers="headers"
    :items="ArrayHistorico"
    :search="search"
    sort-by="calories"
    class="elevation-1 mx-1 mt-2"
  >
    <template v-slot:top>
      <v-toolbar  flat color="opcion3">
        <v-toolbar-title>Gestiones Realizadas</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

          <v-flex  xs4 sm4 md3 lg3>
          <v-text-field  justify-center  v-model="search"  append-icon="search" 
          label="Busqueda" single-line hide-details></v-text-field> 
        </v-flex>


        <v-flex class="mt-4 mx-5" xs4 sm3 md3 lg2 xl2>
          <v-select v-model="id_ejecutivo_activo" dense 
          outlined :items="ArrayEjecutivos" @change="filtrarEjecutivo()"  label="Ejecutivos"></v-select>
       </v-flex>

         <v-flex  mx-md-3 mx-lg-4  mx-sm-2 mx-xs-1 mx-1>
                <v-btn dark color="primary" @click="dialogInicio=true">
                  <v-icon>date_range</v-icon>
                </v-btn>

            </v-flex>


        <vue-excel-xlsx v-show="ArrayHistorico.length >0"
          :data="ArrayHistorico"
          :columns="column_excel"
          :filename="'Gestiones'"
          :sheetname="'hoja1'">
          <v-btn color="info">
              <v-icon dark>get_app</v-icon>
          </v-btn>
        
          </vue-excel-xlsx>



            <v-dialog v-model="dialogInicio" persistent max-width="250">
      <v-card>

        <v-toolbar flat color="red" class="text-center">
        <v-toolbar-title class="white--text  text-center"><strong>Historico por fechas</strong></v-toolbar-title>
      </v-toolbar>

        
        
        <v-card-text>

               <v-col class="d-inline p-3" cols="2">

                <v-menu
                        ref="menuFecha1"
                        v-model="menuFecha1"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                    
                        offset-y
                        min-width="290px"
                      >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="fecha_inicio_reporte"
                                label="Fecha inicio"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="fecha_inicio_reporte" locale="es"
                            no-title  @input="menuFecha1 = false" scrollable>
                            </v-date-picker>
                      </v-menu>
      </v-col>

      <v-col class="d-inline" cols="2">
                      <v-menu
                        ref="menuFecha2"
                        v-model="menuFecha2"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                    
                        offset-y
                        min-width="290px"
                      >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="fecha_fin_reporte"
                                label="Fecha fin"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="fecha_fin_reporte" locale="es"
                            no-title  @input="menuFecha2 = false" scrollable>
                            </v-date-picker>
                      </v-menu>

                    </v-col>


          <v-flex justify-center d-flex>
            <v-btn color="success" dark @click="listar()">Generar</v-btn>
              <v-btn @click="dialogInicio=false" style="margin-left:10px;">Cancelar</v-btn>
          </v-flex>


        </v-card-text>
      

          

      </v-card>
    </v-dialog>

        <v-spacer></v-spacer>

         <v-btn color="success" @click="listar();">
              <v-icon>restart_alt</v-icon>
              </v-btn>

      
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
      <v-icon small @click="deleteItem(item)">delete</v-icon>
    </template>

    <template v-slot:[`item.fecha_tx`]="{ item }">
      <span>{{  getDate(item.fecha_tx) }}</span>
    </template>


    <template v-slot:[`item.ubicacion`]="{ item }">
      <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
    </template>




       <template v-slot:[`item.id_estado`]="{ item }">

         <v-chip v-if="item.id_estado.nombre_estado=='Desconocido'" small color="red" outlined>
           <span>{{ item.id_estado.nombre_estado }}</span>
         </v-chip>


         <v-chip v-else-if="item.id_estado.nombre_estado=='Sin confirmar'" small color="red" outlined>
           <span>{{ item.id_estado.nombre_estado }}</span>
         </v-chip>


         <v-chip v-else small color="blue" outlined>
           <span>{{ item.id_estado.nombre_estado }}</span>
         </v-chip>


    </template>


     <template v-slot:[`item.vence_estado`]="{ item }">
           <span v-show="item.vence_estado!=null">{{ getDateVence(item.vence_estado) }}</span>
    </template>


     <template v-slot:[`item.fecha_gestion`]="{ item }">
           <span v-show="item.fecha_gestion!=null">{{ getDate(item.fecha_gestion) }}</span>
    </template>


     <template v-slot:[`item.creado`]="{ item }">
           <span v-if="item.creado!=''">{{ getDate(item.creado) }}</span>
    </template>



    <template v-slot:no-data>
      <v-btn color="primary" @click="listar()">Reset</v-btn>
    </template>
  </v-data-table>

</div>

  </v-flex>
</v-layout>
</template>

<script>

import axios from 'axios';
import { mapMutations,mapState } from "vuex";


  export default {
    data: () => ({
      ArrayHistorico:[],
      search:'',
      dialog: false,
      dialogDelete: false,
      headers: [
       
        { text: 'id_avl', value: 'id_avl' },
        { text: 'nombre', value: 'nombre' },
        { text: 'placa', value: 'placa' },
        { text: 'flota', value: 'flota' },

        { text: 'Fecha tx', value: 'fecha_tx' },
        { text: 'Ubicacion', value: 'ubicacion' },
        { text: 'Estado', value: 'id_estado' },
        { text: 'Fecha gestion', value: 'fecha_gestion' },
        { text: 'Contactado', value: 'contactado' },
        { text: 'Vence estado', value: 'vence_estado' },
        { text: 'Observacion', value: 'observacion'},
        { text: 'Cliente', value:'id_veh_cliente.nombre_cliente'},
        { text: 'Creado', value:'creado'},
        { text: 'Actualizado',value:'comentario'}

        //{ text: 'Actions', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        fecha_gestion: '',
        vence_estado:'',
      
      },
      defaultItem: {
        fecha_gestion: '',
        vence_estado: '',
        fat: 0,
        carbs: 0,
        protein: 0,
      },

      dialogInicio:false,
      menuFecha1:false,
       menuFecha2:false,
       fecha_inicio_reporte:new Date().toISOString().substr(0, 10),
       fecha_fin_reporte:new Date().toISOString().substr(0, 10),
       date: new Date().toISOString().substr(0, 10),

        tableHeight: 0,
        ArrayEjecutivos:[],
        ArrayHistoricoAll:[],


        column_excel:[ 
                    {label: "id_avl",  field: "id_avl"},
                    {label: "nombre",  field: "nombre"},
                    {label: "placa", field: "placa"},
                    {label: "flota", field: "flota"},

                    {label: "fecha_tx", field: "fecha_tx"},

                    {label: "id_estado",  field: "id_estado"},

                    {label: "fecha_gestion",  field: "fecha_gestion"},
                    {label: "contactado",  field: "contactado"},


                    {label: "vence_estado",  field: "vence_estado"},
                    {label: "nombre_cliente",  field: "id_veh_cliente.nombre_cliente"},

                    {label: "observacion", field: "observacion"},
                    {label: "creado", field: "creado"},
                    {label: "comentario", field: "comentario"}
                    ],

       

    }),

    computed: {
        ...mapState(['usuario', 'token']),

      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
       
        
     //this.dialogInicio=true;
     this.listarEjecutivos();
     this.listar();


    },

    methods: {

      ...mapMutations(['mostrarLoading','ocultarLoading']),


      listar(){

          this.ArrayHistorico = [];
          this.ArrayHistoricoAll=[];

           let config={headers:{token:this.token}};
            this.mostrarLoading({titulo:'Accediendo a datos'});


            let parametros={ 
                               desde:this.fecha_inicio_reporte,
                               hasta:this.fecha_fin_reporte
                             };


            let me=this;
            axios.post('historicos',parametros,
             config
            ).then(function(response){
              console.log(response);
               me.ArrayHistorico=response.data;
               me.ArrayHistoricoAll=response.data;
               me.dialogInicio=false;

              


            me.ocultarLoading();
            }).catch(function(error){
              console.log(error);
            });

      },

      listarEjecutivos(){

        this.ArrayEjecutivos.push({text:'Todos', value:0});
        this.id_ejecutivo_activo=this.ArrayEjecutivos[0].value;

        let config={headers:{token:this.token}};
        this.mostrarLoading({titulo:'Accediendo a datos'});
          let arregloe=[];
         let me=this;
          axios.get('ejecutivos',
         config
          ).then(function(response){
             console.log(response);
             arregloe=response.data;

             arregloe.map(function(x){

                if(x.rol=="ATC"){
                   me.ArrayEjecutivos.push({text:x.nombre_ejecutivo, value:x.nombre_ejecutivo});
                }
             });

           me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });
      },


      filtrarEjecutivo(){ 

        let me=this;


        me.ArrayHistorico=[];

        me.ArrayHistoricoAll.map(function(x){

      
          let actualizado=x.actualizado;
          let arr = actualizado.split(',');


          if((arr[0]==me.id_ejecutivo_activo)||(me.id_ejecutivo_activo==0)){ 

               me.ArrayHistorico.push(x);

           }

         });






      },


      editItem (item) {
        this.editedIndex = this.ArrayHistorico.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.ArrayHistorico.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.ArrayHistorico.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.ArrayHistorico[this.editedIndex], this.editedItem)
        } else {
          this.ArrayHistorico.push(this.editedItem)
        }
        this.close()
      },


    getDate(evaluar) {

      if(evaluar!=null){
      let date = new Date(evaluar),
      year = date.getFullYear(),
      month = (date.getMonth() + 1).toString(),
      formatedMonth = (month.length === 1) ? ("0" + month) : month,
      day = date.getDate().toString(),
      formatedDay = (day.length === 1) ? ("0" + day) : day,
      hour = date.getHours().toString(),
      formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
      minute = date.getMinutes().toString(),
      formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
      second = date.getSeconds().toString(),
      formatedSecond = (second.length === 1) ? ("0" + second) : second;
      return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
    }
    },


     getDateVence(evaluar) {
       if(evaluar!=null){
          let date = new Date(evaluar),
          year = date.getFullYear(),
          month = (date.getMonth() + 1).toString(),
          formatedMonth = (month.length === 1) ? ("0" + month) : month,
          day = date.getDate().toString(),
          formatedDay = (day.length === 1) ? ("0" + day) : day;
        return formatedDay + "-" + formatedMonth + "-" + year;

       }
    },

    openGoogleMap(item){
        const urlSuffix = item.latitud +','+ item.longitud;
        window.open(
          "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
          "_blank"
        );
    },

      onResize() {

                if (window.innerWidth < 769){

                  //mobile
                  this.tableHeight = window.innerHeight - 20;
                }else{
                  //pantalla grande
                  this.tableHeight = window.innerHeight - 330;
                }
       },



    },
  }
</script>